body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}






/** test for sphere */
.element {
  width: 300px;
  height: 160px;
  box-shadow: 0px 0px 12px rgba(0,0,0,0.5);
  /*border: 1px solid rgba(127,255,255,0.25);*/
  font-family: Helvetica, sans-serif;
  text-align: center;
  line-height: normal;
  cursor: default;
}

  .element .number {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 12px;
    color: rgba(255,255,255,0.75);
  }

  .element .symbol {
    position: absolute;
    top: 40px;
    left: 0px;
    right: 0px;
    font-size: 60px;
    font-weight: bold;
    color: rgba(255,255,255,0.75);
    /*text-shadow: 0 0 10px rgba(0,255,255,0.95);*/
  }

  .element .details {
    position: absolute;
    bottom: 15px;
    left: 0px;
    right: 0px;
    font-size: 12px;
    color: rgba(255,255,255,0.75);
  }

  .winner {
    display: inline-block;
    font-size: 10rem;
    background-color: rgba(65,130,151, .9);
    color: rgba(255,255,255,0.75);
    box-shadow: 0px 0px 12px rgba(0,0,0,0.5);
    font-weight: bold;
    padding: 5rem;
    text-align: center;
  }

  .three-container {
    width: 100%;
    height: 100%;

  }


  .bg-image {
    background-image: url("../public/bg.jpeg");
    background-size: cover;
  }